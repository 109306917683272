<template>
    <div style="display: flex;">
        <div class="gh-content-global" style="width: 170px">
            <div style="width: 168px; height: 168px;">
                <img :src="image" width="168" style="border-radius: 6px; max-height: 168px;" alt="">
            </div>
            <div class="other-image">
                <GhEasyUpload
                    :extrablock="{
                        id: 'evaluator_image',
                        class: '',
                        name_hidden: 'evaluator[image]',
                        id_hidden: 'evaluator_image',
                        icon_attach: require('../../../../../assets/gh_new_dessign/attach.svg')
                    }"
                    :datalabel="{
                        id: 'evaluator_image',
                        style: 'margin-right: -15px'
                    }"
                    :datainput="{
                        view: $root._route.params.view,
                        text: $t(haveImage ? 'change_image' : 'attach_image'),
                        show_image: false,
                        delete: false,
                        href: 'easyUpload',
                        downloadHref: 'easyDownload',
                        file_name: getterEvaluatorData.file_name,
                        file_path: getterEvaluatorData.file_path,
                        file_download: getterEvaluatorData.file_download,
                        required: false
                    }"
                />
            </div>
        </div>
        <div class="gh-content-global" style="width: 100%; min-width: 50px;">
            <div class="gh-row-line-flex">
                <div :class="!$root._route.params.view ? 'gh-row-column' : 'gh-row-column-flex'">
                    <template v-if="!$root._route.params.view">
                        <GhInputTextField
                            :datalabel="{
                                text: $t('name') + '*',
                                style:'min-width: 160px !important; text-align: right;',
                                class: 'T15_b'
                            }"
                            :datainput="{
                                required: true,
                                id: 'evaluator_name',
                                name: 'evaluator[name]',
                                style: 'width:60%; min-width: 115px;',
                                value: persistance.name,
                                type: 'text'
                            }"
                            ref="refName"
                        />
                    </template>
                    <template v-else>
                        <div style="display: ruby-base;">
                            <label class="gh_text_field-label T15" style="position: absolute;width: 160px; min-width: 160px; text-align: right;">{{$t('name') + ':'}}</label>
                            <label class="gh_text_field-label T15_b" style="margin-left: 172px;display: block;">{{persistance.name}}</label>
                        </div>
                    </template>
                </div>
                <div :class="!$root._route.params.view ? 'gh-row-column' : 'gh-row-column-flex'">
                    <template v-if="!$root._route.params.view">
                        <GhInputTextField
                            :datalabel="{
                                text: $t('surnames') + '*',
                                style: 'margin-left:20px; min-width: 100px !important; text-align: right;',
                                class: 'T15_b'
                            }"
                            :datainput="{
                                required: true,
                                id: 'evaluator_surname',
                                name: 'evaluator[surname]',
                                style: 'width:60%; min-width: 115px;',
                                value: persistance.surname,
                                type: 'text'
                            }"
                            ref="refSurname"
                        />
                    </template>
                    <template v-else>
                        <div style="display: ruby-base;">
                            <label class="gh_text_field-label T15" style="position: absolute;width: 120px; min-width: 120px; text-align: right;">{{$t('surnames') + ':'}}</label>
                            <label class="gh_text_field-label T15_b" style="margin-left: 132px; display: block;">{{persistance.surname}}</label>
                        </div>
                    </template>
                </div>
                <div :class="!$root._route.params.view ? 'gh-row-column' : 'gh-row-column-flex'">
                    <template v-if="!$root._route.params.view">
                        <GhInputTextField
                            :datalabel="{
                                text: $t('nif_nie') + '*',
                                style: 'margin-left:20px; min-width: 100px !important; text-align: right;',
                                class: 'T15_b'
                            }"
                            :datainput="{
                                required: true,
                                id: 'evaluator_nif',
                                name: 'evaluator[nif]',
                                style: 'width:20%; min-width: 100px;',
                                value: persistance.cif,
                                type: 'text'
                            }"
                            ref="refNif"
                        />
                    </template>
                    <template v-else>
                        <div style="display: ruby-base">
                            <label class="gh_text_field-label T15" style="position: absolute;width: 120px; min-width: 120px; text-align: right;">{{$t('nif_nie') + ':'}}</label>
                            <label class="gh_text_field-label T15_b" style="margin-left: 132px; display: block;">{{persistance.cif}}</label>
                        </div>
                    </template>
                </div>
            </div>
            <div class="gh-row-line-flex">
                <div class="children-contents" style="width: 33.33%; display: flex">
                    <template v-if="!$root._route.params.view">
                        <GhCalendar
                            :datalabel="{
                                id: 'label_evaluator_incorporation',
                                text: $t('incorporation'),
                                style: 'width: 160px; min-width: 160px; text-align: right; margin-right: 12px;'
                            }"
                            :datainput="{
                                id: 'evaluator_incorporation',
                                name: 'evaluator[incorporation]',
                                value: getterEvaluatorData.incorporation !== undefined ? getterEvaluatorData.incorporation : '',
                                required: true,
                                format: 'DD/MM/YYYY',
                                placeholder: 'DD/MM/YYYY'
                            }"
                        />
                    </template>
                    <template v-else>
                        <div style="display: ruby-base">
                            <label class="gh_text_field-label T15" style="position: absolute;width: 160px; min-width: 160px; text-align: right;">{{$t('incorporation') + ':'}}</label>
                            <label class="gh_text_field-label T15_b" style="margin-left: 172px; display: block;">{{getterEvaluatorData.incorporation}}</label>
                        </div>
                    </template>
                </div>
                <div style="width: 66.66%; display: flex">
                    <label class="gh_text_field-label" :class="!$root._route.params.view ? 'T15_b' : 'T15'" style="margin-right: 10px; margin-left: 20px; width: 100px; min-width: 100px; text-align: right;">{{$t('available') + (!$root._route.params.view ? '*' : '')}}</label>
                    <GhChecks
                        :datalabel="{
                            style: '',
                            class: '',
                            id: ''
                        }"
                        :datainput="{
                            required: true,
                            label_required: $t('available') + '*',
                            disabled: $root._route.params.view,
                            selected: getterEvaluatorData.available !== undefined ? getterEvaluatorData.available === 1 : false,
                            type: 'radio',
                            id: 'evaluator_available',
                            value: 1,
                            style: '',
                            name: 'evaluator[available]'
                        }"
                    />
                    <label class="gh_text_field-label T15" style="margin-left: 10px;">{{$t('yes')}}</label>
                    <GhChecks
                        :datalabel="{
                            style: '',
                            class: '',
                            id: ''
                        }"
                        :datainput="{
                            required: true,
                            label_required: $t('available') + '*',
                            disabled: $root._route.params.view,
                            selected: getterEvaluatorData.available !== undefined ? getterEvaluatorData.available === 2 : false,
                            type: 'radio',
                            id: 'evaluator_available',
                            value: 2,
                            style: '',
                            name: 'evaluator[available]'
                        }"
                    />
                    <label class="gh_text_field-label T15" style="margin-left: 10px;">{{$t('no')}}</label>
                </div>
            </div>
            <div class="gh-row-line">
                <div :class="!$root._route.params.view ? 'gh-row-column' : 'gh-row-column-flex'">
                    <template v-if="!$root._route.params.view">
                        <GhInputTextField
                            :datalabel="{
                                text: $t('full_address') + '*',
                                style:'min-width: 160px !important; text-align: right;',
                                class: 'T15_b'
                            }"
                            :datainput="{
                                required: true,
                                id: 'evaluator_full_address',
                                name: 'evaluator[full_address]',
                                style: 'width:60%; min-width: 115px;',
                                value: persistance.full_address,
                                type: 'text'
                            }"
                            ref="refFullAddress"
                        />
                    </template>
                    <template v-else>
                        <div style="display: ruby-base">
                            <label class="gh_text_field-label T15" style="position: absolute;width: 160px; min-width: 160px; text-align: right;">{{$t('full_address') + ':'}}</label>
                            <label class="gh_text_field-label T15_b" style="margin-left: 172px; display: block;">{{persistance.full_address}}</label>
                        </div>
                    </template>
                </div>
                <div :class="!$root._route.params.view ? 'gh-row-column' : 'gh-row-column-flex'">
                    <template v-if="!$root._route.params.view">
                        <GhInputTextField
                            :datalabel="{
                                text: $t('locality') + '*',
                                style: 'margin-left:20px; min-width: 100px !important; text-align: right;',
                                class: 'T15_b'
                            }"
                            :datainput="{
                                required: true,
                                id: 'evaluator_locality',
                                name: 'evaluator[locality]',
                                style: 'width:60%; min-width: 115px;',
                                value: persistance.locality,
                                type: 'text'
                            }"
                            ref="refLocality"
                        />
                    </template>
                    <template v-else>
                        <div style="display: ruby-base">
                            <label class="gh_text_field-label T15" style="position: absolute;width: 120px; min-width: 120px; text-align: right;">{{$t('locality') + ':'}}</label>
                            <label class="gh_text_field-label T15_b" style="margin-left: 132px; display: block;">{{persistance.locality}}</label>
                        </div>
                    </template>
                </div>
                <div :class="!$root._route.params.view ? 'gh-row-column' : 'gh-row-column-flex'">
                    <template v-if="!$root._route.params.view">
                        <GhInputTextField
                            :datalabel="{
                                text: $t('cp') + '*',
                                style: 'margin-left:20px; min-width: 100px !important; text-align: right;',
                                class: 'T15_b'
                            }"
                            :datainput="{
                                required: true,
                                id: 'evaluator_cp',
                                name: 'evaluator[cp]',
                                style: 'width:20%; min-width: 100px;',
                                value: persistance.cp,
                                type: 'text'
                            }"
                            ref="refCp"
                        />
                    </template>
                    <template v-else>
                        <div style="display: ruby-base">
                            <label class="gh_text_field-label T15" style="position: absolute;width: 120px; min-width: 120px; text-align: right;">{{$t('cp') + ':'}}</label>
                            <label class="gh_text_field-label T15_b" style="margin-left: 132px; display: block;">{{persistance.cp}}</label>
                        </div>
                    </template>
                </div>
            </div>
            <div class="gh-row-line">
                <div :class="!$root._route.params.view ? 'gh-row-column no-min-width' : 'gh-row-column-flex'">
                    <template v-if="!$root._route.params.view">
                        <GhDataSelector
                            :datablock="{
                                id: 'evaluator_country',
                                class: 'T15_b',
                                subtitle: $t('select_country') + ':',
                                text: $t('country') + '*',
                                label: $t('country'),
                                type: 'radio',
                                columns: 3,
                                required: true,
                                style: '',
                                popupStyle: 'min-width: 900px;width: 55%;'
                            }"
                            :datainput="{
                                id: 'value_evaluator_country',
                                name: 'evaluator[country]'
                            }"
                            :feed="getFeeds.feed_country"
                            :selected_input="country"
                            @accept="searchCommunity"
                            ref="refCountry"
                        />
                    </template>
                    <template v-else>
                        <div style="display: ruby-base">
                            <label class="gh_text_field-label T15" style="position: absolute;width: 160px; min-width: 160px; text-align: right;">{{$t('country') + ':'}}</label>
                            <label class="gh_text_field-label T15_b" style="margin-left: 172px; display: block;">{{Object.values(country).join()}}</label>
                        </div>
                    </template>
                </div>
                <div :class="!$root._route.params.view ? 'gh-row-column no-min-width' : 'gh-row-column-flex'">
                    <template v-if="!$root._route.params.view">
                        <GhDataSelector
                            :datablock="{
                                id: 'evaluator_community',
                                class: 'T15_b',
                                subtitle: $t('select_community') + ':',
                                text: $t('community') + '*',
                                label: $t('community'),
                                type: 'radio',
                                columns: 3,
                                required: true,
                                style: 'margin-left:20px;',
                                popupStyle: 'min-width: 900px;width: 55%;',
                            }"
                            :datainput="{
                                id: 'value_evaluator_community',
                                name: 'evaluator[community]'
                            }"
                            :feed="getterCommunityByCountry !== undefined ? getterCommunityByCountry : []"
                            :selected_input="community"
                            @accept="searchProvince"
                            ref="refCommunity"
                            :key="'refresh_community_' + refreshCommunity"
                        />
                    </template>
                    <template v-else>
                        <div style="display: ruby-base">
                            <label class="gh_text_field-label T15" style="position: absolute;width: 120px; min-width: 120px; text-align: right;">{{$t('community') + ':'}}</label>
                            <label class="gh_text_field-label T15_b" style="margin-left: 132px; display: block;">{{Object.values(community).join()}}</label>
                        </div>
                    </template>
                </div>
                <div :class="!$root._route.params.view ? 'gh-row-column no-min-width' : 'gh-row-column-flex'">
                    <template v-if="!$root._route.params.view">
                        <GhDataSelector
                            :datablock="{
                                id: 'evaluator_province',
                                class: 'T15_b',
                                subtitle: $t('select_province') + ':',
                                text: $t('province') + '*',
                                label: $t('province'),
                                type: 'radio',
                                columns: 3,
                                required: true,
                                style: 'margin-left:20px;',
                                popupStyle: 'min-width: 900px;width: 55%;'
                            }"
                            :datainput="{
                                id: 'value_evaluator_province',
                                name: 'evaluator[province]'
                            }"
                            :feed="getterProvinceByCommunity !== undefined ? getterProvinceByCommunity : []"
                            :selected_input="province"
                            ref="refProvince"
                            :key="'refresh_province_' + refreshProvince"
                        />
                    </template>
                    <template v-else>
                        <div style="display: ruby-base">
                            <label class="gh_text_field-label T15" style="position: absolute;width: 120px; min-width: 120px; text-align: right;">{{$t('province') + ':'}}</label>
                            <label class="gh_text_field-label T15_b" style="margin-left: 132px; display: block;">{{Object.values(province).join()}}</label>
                        </div>
                    </template>
                </div>
            </div>
            <div class="gh-row-line">
                <div :class="!$root._route.params.view ? 'gh-row-column' : 'gh-row-column-flex'">
                    <template v-if="!$root._route.params.view">
                        <GhInputTextField
                            :datalabel="{
                                text: $t('position') + '*',
                                style:'min-width: 160px !important; text-align: right;',
                                class: 'T15_b'
                            }"
                            :datainput="{
                                required: true,
                                id: 'evaluator_position',
                                name: 'evaluator[position]',
                                style: 'width:60%; min-width: 115px;',
                                value: persistance.position,
                                type: 'text'
                            }"
                            ref="refPosition"
                        />
                    </template>
                    <template v-else>
                        <div style="display: ruby-base">
                            <label class="gh_text_field-label T15" style="position: absolute;width: 160px; min-width: 160px; text-align: right;">{{$t('position') + ':'}}</label>
                            <label class="gh_text_field-label T15_b" style="margin-left: 172px; display: block;">{{persistance.position}}</label>
                        </div>
                    </template>
                </div>
                <div :class="!$root._route.params.view ? 'gh-row-column' : 'gh-row-column-flex'">
                    <template v-if="!$root._route.params.view">
                        <GhInputTextField
                            :datalabel="{
                                text: $t('telephone') + '*',
                                style:'margin-left:20px; min-width: 100px !important; text-align: right;',
                                class: 'T15_b'
                            }"
                            :datainput="{
                                required: true,
                                id: 'evaluator_telephone',
                                name: 'evaluator[telephone]',
                                style: 'width:60%; min-width: 115px;',
                                value: persistance.telephone,
                                type: 'text'
                            }"
                            ref="refTelephone"
                        />
                    </template>
                    <template v-else>
                        <div style="display: ruby-base">
                            <label class="gh_text_field-label T15" style="position: absolute;width: 120px; min-width: 120px; text-align: right;">{{$t('telephone') + ':'}}</label>
                            <label class="gh_text_field-label T15_b" style="margin-left: 132px; display: block;">{{persistance.telephone}}</label>
                        </div>
                    </template>
                </div>
                <div :class="!$root._route.params.view ? 'gh-row-column' : 'gh-row-column-flex'">
                    <template v-if="!$root._route.params.view">
                        <GhInputTextField
                            :datalabel="{
                                text: $t('email') + '*',
                                style:'margin-left:20px; min-width: 100px !important; text-align: right;',
                                class: 'T15_b'
                            }"
                            :datainput="{
                                required: true,
                                id: 'evaluator_email',
                                name: 'evaluator[email]',
                                style: 'width:60%; min-width: 115px;',
                                value: persistance.email,
                                type: 'text'
                            }"
                            ref="refEmail"
                        />
                    </template>
                    <template v-else>
                        <div style="display: ruby-base">
                            <label class="gh_text_field-label T15" style="position: absolute;width: 120px; min-width: 120px; text-align: right;">{{$t('email') + ':'}}</label>
                            <label class="gh_text_field-label T15_b" style="margin-left: 132px; word-break: break-all;  display: block;">{{persistance.email}}</label>
                        </div>
                    </template>
                </div>
            </div>
            <div class="gh-row-line">
                <div :class="!$root._route.params.view ? 'gh-row-column no-min-width' : 'gh-row-column-flex'">
                    <template v-if="!$root._route.params.view">
                        <GhDataSelector
                            :datablock="{
                                id: 'evaluator_category_club',
                                class: 'T15_b',
                                subtitle: $t('select_category_club') + ':',
                                text: $t('category_club') + '*',
                                label: $t('category_club'),
                                type: 'radio',
                                columns: 3,
                                required: true,
                                style: '',
                                popupStyle: 'min-width: 900px;width: 55%;'
                            }"
                            :datainput="{
                                id: 'value_evaluator_category_club',
                                name: 'evaluator[category_club]'
                            }"
                            :feed="getFeeds.feed_categories_club"
                            :selected_input="getterEvaluatorData.category_club !== undefined ? getterEvaluatorData.category_club : []"
                        />
                    </template>
                    <template v-else>
                        <div style="display: ruby-base;">
                            <label class="gh_text_field-label T15" style="position: absolute;width: 160px; min-width: 160px; text-align: right;">{{$t('category_club') + ':'}}</label>
                            <label class="gh_text_field-label T15_b" style="margin-left: 172px;display: block;">{{Object.values(getterEvaluatorData.category_club).join()}}</label>
                        </div>
                    </template>
                </div>
            </div>
            <div class="gh-row-line">
                <div :class="!$root._route.params.view ? 'gh-row-column no-min-width' : 'gh-row-column-flex'">
                    <template v-if="!$root._route.params.view">
                        <GhDataSelector
                            :datablock="{
                                id: 'evaluator_type_evaluation',
                                class: 'T15_b',
                                subtitle: $t('select_type_evaluation') + ':',
                                text: $t('type_evaluation') + '*',
                                label: $t('type_evaluation'),
                                type: 'radio',
                                columns: 3,
                                required: true,
                                style: '',
                                popupStyle: 'min-width: 900px;width: 55%;',
                            }"
                            :datainput="{
                                id: 'value_evaluator_type_evaluation',
                                name: 'evaluator[type_evaluation]'
                            }"
                            :feed="getFeeds.feed_type_evaluation"
                            :selected_input="getterEvaluatorData.type_evaluation !== undefined ? getterEvaluatorData.type_evaluation : []"
                        />
                    </template>
                    <template v-else>
                        <div style="display: ruby-base;">
                            <label class="gh_text_field-label T15" style="position: absolute;width: 160px; min-width: 160px; text-align: right;">{{$t('type_evaluation') + ':'}}</label>
                            <label class="gh_text_field-label T15_b" style="margin-left: 172px;display: block;">{{Object.values(getterEvaluatorData.type_evaluation).join()}}</label>
                        </div>
                    </template>
                </div>
            </div>
            <div class="gh-row-line">
                <div :class="!$root._route.params.view ? 'gh-row-column no-min-width' : 'gh-row-column-flex'">
                    <template v-if="!$root._route.params.view">
                        <GhDataSelector
                            :datablock="{
                                id: 'evaluator_ccaa_where_work',
                                class: 'T15_b',
                                subtitle: $t('select_ccaa_where_work') + ':',
                                text: $t('ccaa_where_work') + '*',
                                label: $t('ccaa_where_work'),
                                type: 'radio',
                                columns: 3,
                                required: true,
                                style: '',
                                popupStyle: 'min-width: 900px;width: 55%;'
                            }"
                            :datainput="{
                                id: 'value_evaluator_ccaa_where_work',
                                name: 'evaluator[ccaa_where_work]'
                            }"
                            :feed="getterCommunityByCountry !== undefined ? getterCommunityByCountry : []"
                            :selected_input="getterEvaluatorData.ccaa_where_work !== undefined ? getterEvaluatorData.ccaa_where_work : []"
                        />
                    </template>
                    <template v-else>
                        <div style="display: ruby-base;">
                            <label class="gh_text_field-label T15" style="position: absolute;width: 160px; min-width: 160px; text-align: right;">{{$t('ccaa_where_work') + ':'}}</label>
                            <label class="gh_text_field-label T15_b" style="margin-left: 172px;display: block;">{{Object.values(getterEvaluatorData.ccaa_where_work).join()}}</label>
                        </div>
                    </template>
                </div>
            </div>
            <div class="gh-row-line">
                <div :class="!$root._route.params.view ? 'gh-row-column no-min-width' : 'gh-row-column-flex'">
                    <template v-if="!$root._route.params.view">
                        <GhDataSelector
                            :datablock="{
                                id: 'evaluator_evaluator_modality',
                                class: 'T15_b',
                                subtitle: $t('select_evaluator_modality') + ':',
                                text: $t('evaluator_modality') + '*',
                                label: $t('evaluator_modality'),
                                type: 'radio',
                                columns: 3,
                                required: true,
                                style: '',
                                popupStyle: 'min-width: 900px;width: 55%;'
                            }"
                            :datainput="{
                                id: 'value_evaluator_evaluator_modality',
                                name: 'evaluator[evaluator_modality]'
                            }"
                            :feed="getFeeds.feed_evaluator_modality"
                            :selected_input="getterEvaluatorData.evaluator_modality !== undefined ? getterEvaluatorData.evaluator_modality : []"
                        />
                    </template>
                    <template v-else>
                        <div style="display: ruby-base;">
                            <label class="gh_text_field-label T15" style="position: absolute;width: 160px; min-width: 160px; text-align: right;">{{$t('evaluator_modality') + ':'}}</label>
                            <label class="gh_text_field-label T15_b" style="margin-left: 172px;display: block;">{{Object.values(getterEvaluatorData.evaluator_modality).join()}}</label>
                        </div>
                    </template>
                </div>
            </div>
            <div class="gh-row-line">
                <div :class="!$root._route.params.view ? 'gh-row-column no-min-width' : 'gh-row-column-flex'">
                    <template v-if="!$root._route.params.view">
                        <GhDataSelector
                            :datablock="{
                                id: 'evaluator_professional_activity_sector',
                                class: 'T15_b',
                                subtitle: $t('select_professional_activity_sector') + ':',
                                text: $t('professional_activ_sector') + '*',
                                label: $t('professional_activity_sector'),
                                type: 'radio',
                                columns: 3,
                                required: true,
                                style: '',
                                popupStyle: 'min-width: 900px;width: 55%;',
                            }"
                            :datainput="{
                                id: 'value_evaluator_professional_activity_sector',
                                name: 'evaluator[professional_activity_sector]'
                            }"
                            :feed="getFeeds.feed_professional_activity_sector"
                            :selected_input="getterEvaluatorData.professional_activity_sector !== undefined ? getterEvaluatorData.professional_activity_sector : []"
                        />
                    </template>
                    <template v-else>
                        <div style="display: ruby-base;">
                            <label class="gh_text_field-label T15" style="position: absolute;width: 160px; min-width: 160px; text-align: right;">{{$t('professional_activ_sector') + ':'}}</label>
                            <label class="gh_text_field-label T15_b" style="margin-left: 172px; display: block;">{{Object.values(getterEvaluatorData.professional_activity_sector).join()}}</label>
                        </div>
                    </template>
                </div>
            </div>
            <div class="gh-row-line">
                <div :class="!$root._route.params.view ? 'gh-row-column no-min-width' : 'gh-row-column-flex'">
                    <template v-if="!$root._route.params.view">
                        <GhDataSelector
                            :datablock="{
                                id: 'evaluator_grade_experience',
                                class: 'T15_b',
                                subtitle: $t('select_grade_experience') + ':',
                                text: $t('grade_experience') + '*',
                                label: $t('grade_experience'),
                                type: 'radio',
                                columns: 3,
                                required: true,
                                style: '',
                                popupStyle: 'min-width: 900px;width: 55%;'
                            }"
                            :datainput="{
                                id: 'value_evaluator_grade_experience',
                                name: 'evaluator[grade_experience]'
                            }"
                            :feed="getFeeds.feed_grade_experience"
                            :selected_input="getterEvaluatorData.grade_experience !== undefined ? getterEvaluatorData.grade_experience : []"
                        />
                    </template>
                    <template v-else>
                        <div style="display: ruby-base;">
                            <label class="gh_text_field-label T15" style="position: absolute;width: 160px; min-width: 160px; text-align: right;">{{$t('grade_experience') + ':'}}</label>
                            <label class="gh_text_field-label T15_b" style="margin-left: 172px; display: block;">{{Object.values(getterEvaluatorData.grade_experience).join()}}</label>
                        </div>
                    </template>
                </div>
            </div>
            <div class="gh-row-line">
                <div :class="!$root._route.params.view ? 'gh-row-column no-min-width' : 'gh-row-column-flex'">
                    <template v-if="!$root._route.params.view">
                        <GhDataSelector
                            :datablock="{
                                id: 'evaluator_professional_experience_sector',
                                class: 'T15_b',
                                subtitle: $t('select_professional_experience_sector') + ':',
                                text: $t('professional_exp_sector') + '*',
                                label: $t('professional_experience_sector'),
                                type: 'checkbox',
                                columns: 3,
                                required: true,
                                style: '',
                                popupStyle: 'min-width: 900px;width: 55%;'
                            }"
                            :datainput="{
                                id: 'value_evaluator_professional_experience_sector',
                                name: 'evaluator[professional_experience_sector][]'
                            }"
                            :feed="getFeeds.feed_professional_experience_sector"
                            :selected_input="getterEvaluatorData.professional_experience_sector !== undefined ? getterEvaluatorData.professional_experience_sector : []"
                        />
                    </template>
                    <template v-else>
                        <div style="display: ruby-base;">
                            <label class="gh_text_field-label T15" style="position: absolute;width: 160px; min-width: 160px; text-align: right;">{{$t('professional_exp_sector') + ':'}}</label>
                            <label class="gh_text_field-label T15_b" style="margin-left: 172px;display: block;">{{Object.values(getterEvaluatorData.professional_experience_sector).join()}}</label>
                        </div>
                    </template>
                </div>
            </div>
            <div class="gh-row-line">
                <div class="gh-row-column">
                    <GhChecks
                        :datalabel="{
                            text: $t('disponibility_to_travel'),
                            style: '',
                            class: '',
                            id: ''
                        }"
                        :datainput="{
                            required: false,
                            disabled: $root._route.params.view,
                            selected: getterEvaluatorData.disponibility_to_travel !== undefined ? getterEvaluatorData.disponibility_to_travel === 1 : false,
                            type: 'checkbox',
                            id: 'evaluator_disponibility_to_travel',
                            value: 1,
                            style: 'margin-left: 172px;',
                            name: 'evaluator[disponibility_to_travel]'
                        }"
                    />
                </div>
            </div>
            <div class="gh-row-line">
                <div :class="!$root._route.params.view ? 'gh-row-column no-min-width' : 'gh-row-column-flex'">
                    <template v-if="!$root._route.params.view">
                        <GhDataSelector
                            :datablock="{
                                id: 'evaluator_communities_where_can_work',
                                class: 'T15_b',
                                subtitle: $t('select_communities_where_can_work') + ':',
                                text: $t('communities_where_can_work') + '*',
                                label: $t('communities_where_can_work'),
                                type: 'checkbox',
                                columns: 3,
                                required: true,
                                inputAll: true,
                                style: '',
                                popupStyle: 'min-width: 900px;width: 55%;'
                            }"
                            :datainput="{
                                id: 'value_evaluator_communities_where_can_work',
                                name: 'evaluator[communities_where_can_work][]'
                            }"
                            :feed="getterCommunityByCountry !== undefined ? getterCommunityByCountry : []"
                            :selected_input="getterEvaluatorData.communities_where_can_work !== undefined ? getterEvaluatorData.communities_where_can_work : []"
                            ref="refCommunitiesWhereCanWork"
                            :key="'refresh_communities_where_can_work_' + refreshCommunity"
                        />
                    </template>
                    <template v-else>
                        <div style="display: ruby-base;">
                            <label class="gh_text_field-label T15" style="position: absolute;width: 160px; min-width: 160px; text-align: right;">{{$t('communities_where_can_work') + ':'}}</label>
                            <label class="gh_text_field-label T15_b" style="margin-left: 172px; display: block;">{{Object.values(getterEvaluatorData.communities_where_can_work).join(', ')}}</label>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import GhEasyUpload from "fe-gh-easy-upload-lib";
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhCalendar from "fe-gh-input-calendar-lib";
    import GhChecks from "fe-gh-checks-lib";
    import GhDataSelector from "fe-gh-data-selector-lib";

    import {mapGetters, mapState} from "vuex";

    export default {
        name: 'content_evaluator',
        components: {
            GhEasyUpload,
            GhInputTextField,
            GhCalendar,
            GhChecks,
            GhDataSelector
        },
        computed: {
            ...mapState(['UploadFiles', 'getFeeds']),
            ...mapGetters(['getterProvinceByCommunity', 'getterCommunityByCountry', 'getterEvaluatorData']),
        },
        data(){
            return {
                image: '',
                haveImage: false,
                refreshProvince: 0,
                refreshCommunity: 0,
                persistance: {
                    name: '',
                    surname: '',
                    cif: '',
                    full_address: '',
                    locality: '',
                    cp: '',
                    position: '',
                    telephone: '',
                    email: ''
                },
                country: [],
                community: [],
                province: []
            }
        },
        async beforeMount(){
            this.persistance.name = this.getterEvaluatorData.name;
            this.persistance.surname = this.getterEvaluatorData.surnames;
            this.persistance.cif = this.getterEvaluatorData.nif;
            this.persistance.full_address = this.getterEvaluatorData.full_address;
            this.persistance.locality = this.getterEvaluatorData.locality;
            this.persistance.cp = this.getterEvaluatorData.cp;
            this.persistance.position = this.getterEvaluatorData.position;
            this.persistance.telephone = this.getterEvaluatorData.telephone;
            this.persistance.email = this.getterEvaluatorData.email;

            this.country = this.getterEvaluatorData.country !== undefined ? this.getterEvaluatorData.country : this.getFeeds.feed_ho_is_spain;

            this.$store.dispatch('getCommunityByCountry', {
                country: Object.keys(this.country)[0],
            }, {root: true});

            if(this.getterEvaluatorData.community !== undefined) {
                this.community = this.getterEvaluatorData.community;

                this.$store.dispatch('getProvinceByCommunity', {
                    community: Object.keys(this.community)[0],
                }, {root: true});
            }

            this.province = this.getterEvaluatorData.province !== undefined ? this.getterEvaluatorData.province : [];

            if(this.getterEvaluatorData.image !== undefined && this.getterEvaluatorData.image !== ''){
                this.image = this.getterEvaluatorData.image;
            }else {
                this.image = require('../../../../../assets/gh_new_dessign/no-image.svg');
            }
        },
        watch:{
            'UploadFiles.data'(data){
                this.image = data.image;
                this.haveImage = true;
            },
            'getterCommunityByCountry'(){
                this.refreshCommunity++;
            },
            'getterProvinceByCommunity'(){
                this.refreshProvince++;
            },
        },
        methods: {
            searchProvince(){
                this.persist();
                var community = this.$refs.refCommunity.checkdata;
                community = Object.keys(community);

                if(community.length !== 0){
                    community = community[0];
                    this.$store.dispatch('getProvinceByCommunity', {
                        community: community,
                    }, {root: true});
                }
            },
            searchCommunity(){
                this.persist();
                var country = this.$refs.refCountry.checkdata;
                country = Object.keys(country);

                if(country.length !== 0){
                    country = country[0];
                    this.$store.dispatch('getCommunityByCountry', {
                        country: country,
                    }, {root: true});
                }
            },
            persist(){
                this.persistance.name = this.$refs['refName'].datainput.value;
                this.persistance.surname = this.$refs['refSurname'].datainput.value;
                this.persistance.cif = this.$refs['refNif'].datainput.value;
                this.persistance.full_address = this.$refs['refFullAddress'].datainput.value;
                this.persistance.locality = this.$refs['refLocality'].datainput.value;
                this.persistance.cp = this.$refs['refCp'].datainput.value;
                this.persistance.position = this.$refs['refPosition'].datainput.value;
                this.persistance.telephone = this.$refs['refTelephone'].datainput.value;
                this.persistance.email = this.$refs['refEmail'].datainput.value;
            }
        }
    }
</script>

<style>
    .other-image > .easy_upload_block{
        flex-direction: initial;
    }
    .other-image > div > #evaluator_image_easy_download_block{
        display: none !important;
    }
    #evaluator_country_selector_label, #evaluator_category_club_selector_label, #evaluator_type_evaluation_selector_label, #evaluator_ccaa_where_work_selector_label, #evaluator_evaluator_modality_selector_label, #evaluator_professional_activity_sector_selector_label, #evaluator_grade_experience_selector_label, #evaluator_professional_experience_sector_selector_label, #evaluator_communities_where_can_work_selector_label{
        width: 160px !important;
        text-align: right;
    }
    #evaluator_community_selector_label, #evaluator_province_selector_label{
        width: 100px !important;
        text-align: right;
    }
    #evaluator_country_data_select_SUMMARY, #evaluator_community_data_select_SUMMARY, #evaluator_province_data_select_SUMMARY{
        min-width: 58px;
    }
    .children-contents > div > .mx-datepicker > .mx-input-wrapper{
        min-width: 120px;
    }
</style>