var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GhPopUp',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"generalConfiguration":{
        id: 'popup_weighting',
        title: _vm.$t('assing_weighting_to_program'),
        type: 'add',
        style: 'width: 40%; min-width: 940px'
    },"bodyConfiguration":{
        id: '',
        class: '',
        style: 'padding:2px;max-height: 40vh; min-height: 2vh; overflow-y: auto !important;',
        text: _vm.$t('assing_weighting_to_program') + ' (' + _vm.getterInfoAgrupator.title + ')'
    },"footerConfiguration":{
        id: '',
        class: '',
        style: '',
        content: ''
    }},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('form',{key:'REFRESHER_ASSIGN' + _vm.refresher,attrs:{"id":"form_weighting"}},[_c('div',{staticClass:"gh-content-global"},_vm._l((_vm.getterWeightingElementAgrupator),function(program,keyProgram){return _c('div',{key:'PROGRAM' + keyProgram,staticStyle:{"width":"100%"},style:(keyProgram % 2 ? 'background-color:  var(--pallette-three) !important' : '')},[_c('div',{staticClass:"gh-row-content"},[_c('GhInputTextField',{ref:'refWeighting' + keyProgram,refInFor:true,attrs:{"datalabel":{
                                text: program.name + '*',
                                style: 'width: 160px; text-align: right;',
                                class: 'T15_b'
                            },"datainput":{
                                required: true,
                                id: 'assignation_base_associated_weighting_groups_' + keyProgram,
                                name: 'weighting[' + keyProgram + ']',
                                style: 'min-width: 40px; width: 40px',
                                value: _vm.propDataWeighting[keyProgram] !== undefined && _vm.propDataWeighting[keyProgram].value !== undefined && _vm.propDataWeighting[keyProgram].value !== '' ? _vm.propDataWeighting[keyProgram].value : '',
                                type: 'int',
                                limitNumMax: _vm.propMaxlength
                            }},on:{"focusout":function($event){return _vm.controlWeighting('refWeighting' + keyProgram, keyProgram)}}})],1)])}),0)])]},proxy:true},{key:"footer",fn:function(){return [_c('GhButton',{attrs:{"datainput":{
                id: 'button_accept_assign',
                text: _vm.$t('accept'),
                class: 'T19 container-md_button_content',
                style: ' display: flex;'
            }},on:{"click":_vm.setWeighting}}),_c('GhButton',{attrs:{"datainput":{
                id: 'button_close_assign',
                text: _vm.$t('close'),
                class: 'T19 container-md_button_content',
                style: ' display: flex;'
            }},on:{"click":function($event){return _vm.Global.closePopUp('popup_weighting');}}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }