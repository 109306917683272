import InitialItinerary from "@/view/shared/questionnaire/body.vue";
import ListQuizz from "@/view/itinerary/initial/list_quizz";
import InitialQuizz from "@/view/base_creation/exercises/exercises/template/quizz.vue";
import ResultQuizz from "@/view/itinerary/initial/result_quizz";
import ToPay from "@/view/itinerary/initial/to_payment";
import JointMap from "@/view/shared/template/joint_map.vue";

const routes = [
    {
        path: '/questionnary/:type/:id',
        name: 'initial_itinerary',
        props: {showMenu: false, showEmptyMenu: true, id: null, type: null, questionnaireBackground: true},
        component: InitialItinerary
    },
    {
        path: '/questionnary/joint-map/:type/:id/:extra_id',
        name: 'joint_map',
        props: {id: null, type: null, showMenu: true, extra_id: null, questionnaireBackground: true,},
        component: JointMap
    },
    {
        path: '/questionnary/:type/:id/realize/:extra_id/:read_only',
        name: 'initial_itinerary_window',
        props: {showMenu: true, id: null, type: null, extra_id: null, read_only: null, questionnaireBackground: true},
        component: InitialItinerary
    },
    {
        path: '/itinerary/initial',
        name: 'list_quizz',
        props: {showMenu: false},
        component: ListQuizz,
        children: [
            {
                path: '/itinerary/initial/quizz',
                name: 'quizz_detail',
                props: {showMenu: false},
                component: InitialQuizz,
            },
        ]
    },
    {
        path: '/initial_quizz',
        name: 'initial_quizz',
        props: {showMenu: false},
        component: InitialQuizz
    },
    {
        path: '/result_quizz',
        name: 'result_quizz',
        props: {showMenu: false, showEmptyMenu: true, questionnaireBackground: true},
        component: ResultQuizz
    },
    {
        path: '/result_quizz/:id',
        name: 'result_quizz_initial',
        props: {showMenu: false, showEmptyMenu: true, id: null, questionnaireBackground: true},
        component: ResultQuizz
    },
    {
        path: '/to_pay/:id',
        name: 'to_pay',
        props: {showMenu: false, showEmptyMenu: true, questionnaireBackground: true, id: null},
        component: ToPay
    },
];

export default routes;