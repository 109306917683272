<template>
    <GhPopUp v-show="false"
        :generalConfiguration="{
            id: 'popup_weighting',
            title: $t('assing_weighting_to_program'),
            type: 'add',
            style: 'width: 40%; min-width: 940px'
        }"
        :bodyConfiguration="{
            id: '',
            class: '',
            style: 'padding:2px;max-height: 40vh; min-height: 2vh; overflow-y: auto !important;',
            text: $t('assing_weighting_to_program') + ' (' + getterInfoAgrupator.title + ')'
        }"
        :footerConfiguration="{
            id: '',
            class: '',
            style: '',
            content: ''
        }"
    >
        <template v-slot:body>
            <form id="form_weighting" :key="'REFRESHER_ASSIGN' + refresher">
                <div class="gh-content-global">
                    <div v-for="(program, keyProgram) in getterWeightingElementAgrupator" :key="'PROGRAM' + keyProgram" :style="keyProgram % 2 ? 'background-color:  var(--pallette-three) !important' : ''" style="width: 100%;">
                        <div class="gh-row-content">
                            <GhInputTextField
                                :datalabel="{
                                    text: program.name + '*',
                                    style: 'width: 160px; text-align: right;',
                                    class: 'T15_b'
                                }"
                                :datainput="{
                                    required: true,
                                    id: 'assignation_base_associated_weighting_groups_' + keyProgram,
                                    name: 'weighting[' + keyProgram + ']',
                                    style: 'min-width: 40px; width: 40px',
                                    value: propDataWeighting[keyProgram] !== undefined && propDataWeighting[keyProgram].value !== undefined && propDataWeighting[keyProgram].value !== '' ? propDataWeighting[keyProgram].value : '',
                                    type: 'int',
                                    limitNumMax: propMaxlength
                                }"
                                @focusout="controlWeighting('refWeighting' + keyProgram, keyProgram)"
                                :ref="'refWeighting' + keyProgram"
                            />
                        </div>
                    </div>
                </div>
            </form>
        </template>
        <template v-slot:footer>
            <GhButton
                :datainput="{
                    id: 'button_accept_assign',
                    text: $t('accept'),
                    class: 'T19 container-md_button_content',
                    style: ' display: flex;'
                }"
                @click="setWeighting"
            />
            <GhButton
                :datainput="{
                    id: 'button_close_assign',
                    text: $t('close'),
                    class: 'T19 container-md_button_content',
                    style: ' display: flex;'
                }"
                @click="Global.closePopUp('popup_weighting');"
            />
        </template>
    </GhPopUp>
</template>

<script>
    import GhPopUp from "fe-gh-popup-lib";
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhButton from "fe-gh-button-lib";

    import {mapGetters} from "vuex";


    export default {
        name: "popup_assign",
        props: ['refresher', 'dataWeighting', 'maxlength'],
        computed: {
            ...mapGetters(['getterWeightingElementAgrupator', 'getterInfoAgrupator'])
        },
        data(){
            return {
                propDataWeighting: [],
                propMaxlength: this.$props.maxlength
            }
        },
        watch: {
            'refresher'(){
                this.propDataWeighting = this.$props.dataWeighting;
                this.propMaxlength = parseInt(this.$props.maxlength);
                console.log(this.propMaxlength);
            }
        },
        components: {
            GhPopUp,
            GhInputTextField,
            GhButton
        },
        methods: {
            controlWeighting(ref, id){
                var num = parseInt(this.$refs[ref][0].datainput.value, 10);

                if (num % 5 !== 0) {
                    num = Math.floor(num / 5) * 5;
                }

                this.propDataWeighting[id].value = num;
                this.propMaxlength = 100;

                Object.keys(this.propDataWeighting).forEach((i) => {
                    this.propMaxlength = this.propMaxlength - parseInt(this.propDataWeighting[i].value);
                });
            },
            async setWeighting(){
                if(this.Global.checkRequired('form_weighting') === 'OK') {
                    const formData = new FormData(document.getElementById('form_weighting'));

                    await this.$store.dispatch('setWeightingByAgrupatorAndCompany', {
                        formData: [...formData],
                        id: this.getterInfoAgrupator.id,
                        from: this.getterInfoAgrupator.from,
                        agrupator_id: this.getterInfoAgrupator.agrupator_id
                    }, {root: true});
                }
            }
        }
    }
</script>

<style>
    [id^="label_user_assign_"][id$="_selector_label"]{
        width: 150px;
        min-width: 150px !important;
        text-align: right;
    }

    #label_user_assign_permission-Summary{
        display: none !important;
    }
</style>