var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"gh-content-global",staticStyle:{"width":"170px"}},[_c('div',{staticStyle:{"width":"168px","height":"168px"}},[_c('img',{staticStyle:{"border-radius":"6px","max-height":"168px"},attrs:{"src":_vm.image,"width":"168","alt":""}})]),_c('div',{staticClass:"other-image"},[_c('GhEasyUpload',{attrs:{"extrablock":{
                    id: 'evaluator_image',
                    class: '',
                    name_hidden: 'evaluator[image]',
                    id_hidden: 'evaluator_image',
                    icon_attach: require('../../../../../assets/gh_new_dessign/attach.svg')
                },"datalabel":{
                    id: 'evaluator_image',
                    style: 'margin-right: -15px'
                },"datainput":{
                    view: _vm.$root._route.params.view,
                    text: _vm.$t(_vm.haveImage ? 'change_image' : 'attach_image'),
                    show_image: false,
                    delete: false,
                    href: 'easyUpload',
                    downloadHref: 'easyDownload',
                    file_name: _vm.getterEvaluatorData.file_name,
                    file_path: _vm.getterEvaluatorData.file_path,
                    file_download: _vm.getterEvaluatorData.file_download,
                    required: false
                }}})],1)]),_c('div',{staticClass:"gh-content-global",staticStyle:{"width":"100%","min-width":"50px"}},[_c('div',{staticClass:"gh-row-line-flex"},[_c('div',{class:!_vm.$root._route.params.view ? 'gh-row-column' : 'gh-row-column-flex'},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{ref:"refName",attrs:{"datalabel":{
                            text: _vm.$t('name') + '*',
                            style:'min-width: 160px !important; text-align: right;',
                            class: 'T15_b'
                        },"datainput":{
                            required: true,
                            id: 'evaluator_name',
                            name: 'evaluator[name]',
                            style: 'width:60%; min-width: 115px;',
                            value: _vm.persistance.name,
                            type: 'text'
                        }}})]:[_c('div',{staticStyle:{"display":"ruby-base"}},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"position":"absolute","width":"160px","min-width":"160px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('name') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"172px","display":"block"}},[_vm._v(_vm._s(_vm.persistance.name))])])]],2),_c('div',{class:!_vm.$root._route.params.view ? 'gh-row-column' : 'gh-row-column-flex'},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{ref:"refSurname",attrs:{"datalabel":{
                            text: _vm.$t('surnames') + '*',
                            style: 'margin-left:20px; min-width: 100px !important; text-align: right;',
                            class: 'T15_b'
                        },"datainput":{
                            required: true,
                            id: 'evaluator_surname',
                            name: 'evaluator[surname]',
                            style: 'width:60%; min-width: 115px;',
                            value: _vm.persistance.surname,
                            type: 'text'
                        }}})]:[_c('div',{staticStyle:{"display":"ruby-base"}},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"position":"absolute","width":"120px","min-width":"120px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('surnames') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"132px","display":"block"}},[_vm._v(_vm._s(_vm.persistance.surname))])])]],2),_c('div',{class:!_vm.$root._route.params.view ? 'gh-row-column' : 'gh-row-column-flex'},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{ref:"refNif",attrs:{"datalabel":{
                            text: _vm.$t('nif_nie') + '*',
                            style: 'margin-left:20px; min-width: 100px !important; text-align: right;',
                            class: 'T15_b'
                        },"datainput":{
                            required: true,
                            id: 'evaluator_nif',
                            name: 'evaluator[nif]',
                            style: 'width:20%; min-width: 100px;',
                            value: _vm.persistance.cif,
                            type: 'text'
                        }}})]:[_c('div',{staticStyle:{"display":"ruby-base"}},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"position":"absolute","width":"120px","min-width":"120px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('nif_nie') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"132px","display":"block"}},[_vm._v(_vm._s(_vm.persistance.cif))])])]],2)]),_c('div',{staticClass:"gh-row-line-flex"},[_c('div',{staticClass:"children-contents",staticStyle:{"width":"33.33%","display":"flex"}},[(!_vm.$root._route.params.view)?[_c('GhCalendar',{attrs:{"datalabel":{
                            id: 'label_evaluator_incorporation',
                            text: _vm.$t('incorporation'),
                            style: 'width: 160px; min-width: 160px; text-align: right; margin-right: 12px;'
                        },"datainput":{
                            id: 'evaluator_incorporation',
                            name: 'evaluator[incorporation]',
                            value: _vm.getterEvaluatorData.incorporation !== undefined ? _vm.getterEvaluatorData.incorporation : '',
                            required: true,
                            format: 'DD/MM/YYYY',
                            placeholder: 'DD/MM/YYYY'
                        }}})]:[_c('div',{staticStyle:{"display":"ruby-base"}},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"position":"absolute","width":"160px","min-width":"160px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('incorporation') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"172px","display":"block"}},[_vm._v(_vm._s(_vm.getterEvaluatorData.incorporation))])])]],2),_c('div',{staticStyle:{"width":"66.66%","display":"flex"}},[_c('label',{staticClass:"gh_text_field-label",class:!_vm.$root._route.params.view ? 'T15_b' : 'T15',staticStyle:{"margin-right":"10px","margin-left":"20px","width":"100px","min-width":"100px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('available') + (!_vm.$root._route.params.view ? '*' : '')))]),_c('GhChecks',{attrs:{"datalabel":{
                        style: '',
                        class: '',
                        id: ''
                    },"datainput":{
                        required: true,
                        label_required: _vm.$t('available') + '*',
                        disabled: _vm.$root._route.params.view,
                        selected: _vm.getterEvaluatorData.available !== undefined ? _vm.getterEvaluatorData.available === 1 : false,
                        type: 'radio',
                        id: 'evaluator_available',
                        value: 1,
                        style: '',
                        name: 'evaluator[available]'
                    }}}),_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.$t('yes')))]),_c('GhChecks',{attrs:{"datalabel":{
                        style: '',
                        class: '',
                        id: ''
                    },"datainput":{
                        required: true,
                        label_required: _vm.$t('available') + '*',
                        disabled: _vm.$root._route.params.view,
                        selected: _vm.getterEvaluatorData.available !== undefined ? _vm.getterEvaluatorData.available === 2 : false,
                        type: 'radio',
                        id: 'evaluator_available',
                        value: 2,
                        style: '',
                        name: 'evaluator[available]'
                    }}}),_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.$t('no')))])],1)]),_c('div',{staticClass:"gh-row-line"},[_c('div',{class:!_vm.$root._route.params.view ? 'gh-row-column' : 'gh-row-column-flex'},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{ref:"refFullAddress",attrs:{"datalabel":{
                            text: _vm.$t('full_address') + '*',
                            style:'min-width: 160px !important; text-align: right;',
                            class: 'T15_b'
                        },"datainput":{
                            required: true,
                            id: 'evaluator_full_address',
                            name: 'evaluator[full_address]',
                            style: 'width:60%; min-width: 115px;',
                            value: _vm.persistance.full_address,
                            type: 'text'
                        }}})]:[_c('div',{staticStyle:{"display":"ruby-base"}},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"position":"absolute","width":"160px","min-width":"160px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('full_address') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"172px","display":"block"}},[_vm._v(_vm._s(_vm.persistance.full_address))])])]],2),_c('div',{class:!_vm.$root._route.params.view ? 'gh-row-column' : 'gh-row-column-flex'},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{ref:"refLocality",attrs:{"datalabel":{
                            text: _vm.$t('locality') + '*',
                            style: 'margin-left:20px; min-width: 100px !important; text-align: right;',
                            class: 'T15_b'
                        },"datainput":{
                            required: true,
                            id: 'evaluator_locality',
                            name: 'evaluator[locality]',
                            style: 'width:60%; min-width: 115px;',
                            value: _vm.persistance.locality,
                            type: 'text'
                        }}})]:[_c('div',{staticStyle:{"display":"ruby-base"}},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"position":"absolute","width":"120px","min-width":"120px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('locality') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"132px","display":"block"}},[_vm._v(_vm._s(_vm.persistance.locality))])])]],2),_c('div',{class:!_vm.$root._route.params.view ? 'gh-row-column' : 'gh-row-column-flex'},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{ref:"refCp",attrs:{"datalabel":{
                            text: _vm.$t('cp') + '*',
                            style: 'margin-left:20px; min-width: 100px !important; text-align: right;',
                            class: 'T15_b'
                        },"datainput":{
                            required: true,
                            id: 'evaluator_cp',
                            name: 'evaluator[cp]',
                            style: 'width:20%; min-width: 100px;',
                            value: _vm.persistance.cp,
                            type: 'text'
                        }}})]:[_c('div',{staticStyle:{"display":"ruby-base"}},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"position":"absolute","width":"120px","min-width":"120px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('cp') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"132px","display":"block"}},[_vm._v(_vm._s(_vm.persistance.cp))])])]],2)]),_c('div',{staticClass:"gh-row-line"},[_c('div',{class:!_vm.$root._route.params.view ? 'gh-row-column no-min-width' : 'gh-row-column-flex'},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{ref:"refCountry",attrs:{"datablock":{
                            id: 'evaluator_country',
                            class: 'T15_b',
                            subtitle: _vm.$t('select_country') + ':',
                            text: _vm.$t('country') + '*',
                            label: _vm.$t('country'),
                            type: 'radio',
                            columns: 3,
                            required: true,
                            style: '',
                            popupStyle: 'min-width: 900px;width: 55%;'
                        },"datainput":{
                            id: 'value_evaluator_country',
                            name: 'evaluator[country]'
                        },"feed":_vm.getFeeds.feed_country,"selected_input":_vm.country},on:{"accept":_vm.searchCommunity}})]:[_c('div',{staticStyle:{"display":"ruby-base"}},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"position":"absolute","width":"160px","min-width":"160px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('country') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"172px","display":"block"}},[_vm._v(_vm._s(Object.values(_vm.country).join()))])])]],2),_c('div',{class:!_vm.$root._route.params.view ? 'gh-row-column no-min-width' : 'gh-row-column-flex'},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{key:'refresh_community_' + _vm.refreshCommunity,ref:"refCommunity",attrs:{"datablock":{
                            id: 'evaluator_community',
                            class: 'T15_b',
                            subtitle: _vm.$t('select_community') + ':',
                            text: _vm.$t('community') + '*',
                            label: _vm.$t('community'),
                            type: 'radio',
                            columns: 3,
                            required: true,
                            style: 'margin-left:20px;',
                            popupStyle: 'min-width: 900px;width: 55%;',
                        },"datainput":{
                            id: 'value_evaluator_community',
                            name: 'evaluator[community]'
                        },"feed":_vm.getterCommunityByCountry !== undefined ? _vm.getterCommunityByCountry : [],"selected_input":_vm.community},on:{"accept":_vm.searchProvince}})]:[_c('div',{staticStyle:{"display":"ruby-base"}},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"position":"absolute","width":"120px","min-width":"120px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('community') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"132px","display":"block"}},[_vm._v(_vm._s(Object.values(_vm.community).join()))])])]],2),_c('div',{class:!_vm.$root._route.params.view ? 'gh-row-column no-min-width' : 'gh-row-column-flex'},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{key:'refresh_province_' + _vm.refreshProvince,ref:"refProvince",attrs:{"datablock":{
                            id: 'evaluator_province',
                            class: 'T15_b',
                            subtitle: _vm.$t('select_province') + ':',
                            text: _vm.$t('province') + '*',
                            label: _vm.$t('province'),
                            type: 'radio',
                            columns: 3,
                            required: true,
                            style: 'margin-left:20px;',
                            popupStyle: 'min-width: 900px;width: 55%;'
                        },"datainput":{
                            id: 'value_evaluator_province',
                            name: 'evaluator[province]'
                        },"feed":_vm.getterProvinceByCommunity !== undefined ? _vm.getterProvinceByCommunity : [],"selected_input":_vm.province}})]:[_c('div',{staticStyle:{"display":"ruby-base"}},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"position":"absolute","width":"120px","min-width":"120px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('province') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"132px","display":"block"}},[_vm._v(_vm._s(Object.values(_vm.province).join()))])])]],2)]),_c('div',{staticClass:"gh-row-line"},[_c('div',{class:!_vm.$root._route.params.view ? 'gh-row-column' : 'gh-row-column-flex'},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{ref:"refPosition",attrs:{"datalabel":{
                            text: _vm.$t('position') + '*',
                            style:'min-width: 160px !important; text-align: right;',
                            class: 'T15_b'
                        },"datainput":{
                            required: true,
                            id: 'evaluator_position',
                            name: 'evaluator[position]',
                            style: 'width:60%; min-width: 115px;',
                            value: _vm.persistance.position,
                            type: 'text'
                        }}})]:[_c('div',{staticStyle:{"display":"ruby-base"}},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"position":"absolute","width":"160px","min-width":"160px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('position') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"172px","display":"block"}},[_vm._v(_vm._s(_vm.persistance.position))])])]],2),_c('div',{class:!_vm.$root._route.params.view ? 'gh-row-column' : 'gh-row-column-flex'},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{ref:"refTelephone",attrs:{"datalabel":{
                            text: _vm.$t('telephone') + '*',
                            style:'margin-left:20px; min-width: 100px !important; text-align: right;',
                            class: 'T15_b'
                        },"datainput":{
                            required: true,
                            id: 'evaluator_telephone',
                            name: 'evaluator[telephone]',
                            style: 'width:60%; min-width: 115px;',
                            value: _vm.persistance.telephone,
                            type: 'text'
                        }}})]:[_c('div',{staticStyle:{"display":"ruby-base"}},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"position":"absolute","width":"120px","min-width":"120px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('telephone') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"132px","display":"block"}},[_vm._v(_vm._s(_vm.persistance.telephone))])])]],2),_c('div',{class:!_vm.$root._route.params.view ? 'gh-row-column' : 'gh-row-column-flex'},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{ref:"refEmail",attrs:{"datalabel":{
                            text: _vm.$t('email') + '*',
                            style:'margin-left:20px; min-width: 100px !important; text-align: right;',
                            class: 'T15_b'
                        },"datainput":{
                            required: true,
                            id: 'evaluator_email',
                            name: 'evaluator[email]',
                            style: 'width:60%; min-width: 115px;',
                            value: _vm.persistance.email,
                            type: 'text'
                        }}})]:[_c('div',{staticStyle:{"display":"ruby-base"}},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"position":"absolute","width":"120px","min-width":"120px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('email') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"132px","word-break":"break-all","display":"block"}},[_vm._v(_vm._s(_vm.persistance.email))])])]],2)]),_c('div',{staticClass:"gh-row-line"},[_c('div',{class:!_vm.$root._route.params.view ? 'gh-row-column no-min-width' : 'gh-row-column-flex'},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{attrs:{"datablock":{
                            id: 'evaluator_category_club',
                            class: 'T15_b',
                            subtitle: _vm.$t('select_category_club') + ':',
                            text: _vm.$t('category_club') + '*',
                            label: _vm.$t('category_club'),
                            type: 'radio',
                            columns: 3,
                            required: true,
                            style: '',
                            popupStyle: 'min-width: 900px;width: 55%;'
                        },"datainput":{
                            id: 'value_evaluator_category_club',
                            name: 'evaluator[category_club]'
                        },"feed":_vm.getFeeds.feed_categories_club,"selected_input":_vm.getterEvaluatorData.category_club !== undefined ? _vm.getterEvaluatorData.category_club : []}})]:[_c('div',{staticStyle:{"display":"ruby-base"}},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"position":"absolute","width":"160px","min-width":"160px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('category_club') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"172px","display":"block"}},[_vm._v(_vm._s(Object.values(_vm.getterEvaluatorData.category_club).join()))])])]],2)]),_c('div',{staticClass:"gh-row-line"},[_c('div',{class:!_vm.$root._route.params.view ? 'gh-row-column no-min-width' : 'gh-row-column-flex'},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{attrs:{"datablock":{
                            id: 'evaluator_type_evaluation',
                            class: 'T15_b',
                            subtitle: _vm.$t('select_type_evaluation') + ':',
                            text: _vm.$t('type_evaluation') + '*',
                            label: _vm.$t('type_evaluation'),
                            type: 'radio',
                            columns: 3,
                            required: true,
                            style: '',
                            popupStyle: 'min-width: 900px;width: 55%;',
                        },"datainput":{
                            id: 'value_evaluator_type_evaluation',
                            name: 'evaluator[type_evaluation]'
                        },"feed":_vm.getFeeds.feed_type_evaluation,"selected_input":_vm.getterEvaluatorData.type_evaluation !== undefined ? _vm.getterEvaluatorData.type_evaluation : []}})]:[_c('div',{staticStyle:{"display":"ruby-base"}},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"position":"absolute","width":"160px","min-width":"160px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('type_evaluation') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"172px","display":"block"}},[_vm._v(_vm._s(Object.values(_vm.getterEvaluatorData.type_evaluation).join()))])])]],2)]),_c('div',{staticClass:"gh-row-line"},[_c('div',{class:!_vm.$root._route.params.view ? 'gh-row-column no-min-width' : 'gh-row-column-flex'},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{attrs:{"datablock":{
                            id: 'evaluator_ccaa_where_work',
                            class: 'T15_b',
                            subtitle: _vm.$t('select_ccaa_where_work') + ':',
                            text: _vm.$t('ccaa_where_work') + '*',
                            label: _vm.$t('ccaa_where_work'),
                            type: 'radio',
                            columns: 3,
                            required: true,
                            style: '',
                            popupStyle: 'min-width: 900px;width: 55%;'
                        },"datainput":{
                            id: 'value_evaluator_ccaa_where_work',
                            name: 'evaluator[ccaa_where_work]'
                        },"feed":_vm.getterCommunityByCountry !== undefined ? _vm.getterCommunityByCountry : [],"selected_input":_vm.getterEvaluatorData.ccaa_where_work !== undefined ? _vm.getterEvaluatorData.ccaa_where_work : []}})]:[_c('div',{staticStyle:{"display":"ruby-base"}},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"position":"absolute","width":"160px","min-width":"160px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('ccaa_where_work') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"172px","display":"block"}},[_vm._v(_vm._s(Object.values(_vm.getterEvaluatorData.ccaa_where_work).join()))])])]],2)]),_c('div',{staticClass:"gh-row-line"},[_c('div',{class:!_vm.$root._route.params.view ? 'gh-row-column no-min-width' : 'gh-row-column-flex'},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{attrs:{"datablock":{
                            id: 'evaluator_evaluator_modality',
                            class: 'T15_b',
                            subtitle: _vm.$t('select_evaluator_modality') + ':',
                            text: _vm.$t('evaluator_modality') + '*',
                            label: _vm.$t('evaluator_modality'),
                            type: 'radio',
                            columns: 3,
                            required: true,
                            style: '',
                            popupStyle: 'min-width: 900px;width: 55%;'
                        },"datainput":{
                            id: 'value_evaluator_evaluator_modality',
                            name: 'evaluator[evaluator_modality]'
                        },"feed":_vm.getFeeds.feed_evaluator_modality,"selected_input":_vm.getterEvaluatorData.evaluator_modality !== undefined ? _vm.getterEvaluatorData.evaluator_modality : []}})]:[_c('div',{staticStyle:{"display":"ruby-base"}},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"position":"absolute","width":"160px","min-width":"160px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('evaluator_modality') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"172px","display":"block"}},[_vm._v(_vm._s(Object.values(_vm.getterEvaluatorData.evaluator_modality).join()))])])]],2)]),_c('div',{staticClass:"gh-row-line"},[_c('div',{class:!_vm.$root._route.params.view ? 'gh-row-column no-min-width' : 'gh-row-column-flex'},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{attrs:{"datablock":{
                            id: 'evaluator_professional_activity_sector',
                            class: 'T15_b',
                            subtitle: _vm.$t('select_professional_activity_sector') + ':',
                            text: _vm.$t('professional_activ_sector') + '*',
                            label: _vm.$t('professional_activity_sector'),
                            type: 'radio',
                            columns: 3,
                            required: true,
                            style: '',
                            popupStyle: 'min-width: 900px;width: 55%;',
                        },"datainput":{
                            id: 'value_evaluator_professional_activity_sector',
                            name: 'evaluator[professional_activity_sector]'
                        },"feed":_vm.getFeeds.feed_professional_activity_sector,"selected_input":_vm.getterEvaluatorData.professional_activity_sector !== undefined ? _vm.getterEvaluatorData.professional_activity_sector : []}})]:[_c('div',{staticStyle:{"display":"ruby-base"}},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"position":"absolute","width":"160px","min-width":"160px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('professional_activ_sector') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"172px","display":"block"}},[_vm._v(_vm._s(Object.values(_vm.getterEvaluatorData.professional_activity_sector).join()))])])]],2)]),_c('div',{staticClass:"gh-row-line"},[_c('div',{class:!_vm.$root._route.params.view ? 'gh-row-column no-min-width' : 'gh-row-column-flex'},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{attrs:{"datablock":{
                            id: 'evaluator_grade_experience',
                            class: 'T15_b',
                            subtitle: _vm.$t('select_grade_experience') + ':',
                            text: _vm.$t('grade_experience') + '*',
                            label: _vm.$t('grade_experience'),
                            type: 'radio',
                            columns: 3,
                            required: true,
                            style: '',
                            popupStyle: 'min-width: 900px;width: 55%;'
                        },"datainput":{
                            id: 'value_evaluator_grade_experience',
                            name: 'evaluator[grade_experience]'
                        },"feed":_vm.getFeeds.feed_grade_experience,"selected_input":_vm.getterEvaluatorData.grade_experience !== undefined ? _vm.getterEvaluatorData.grade_experience : []}})]:[_c('div',{staticStyle:{"display":"ruby-base"}},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"position":"absolute","width":"160px","min-width":"160px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('grade_experience') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"172px","display":"block"}},[_vm._v(_vm._s(Object.values(_vm.getterEvaluatorData.grade_experience).join()))])])]],2)]),_c('div',{staticClass:"gh-row-line"},[_c('div',{class:!_vm.$root._route.params.view ? 'gh-row-column no-min-width' : 'gh-row-column-flex'},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{attrs:{"datablock":{
                            id: 'evaluator_professional_experience_sector',
                            class: 'T15_b',
                            subtitle: _vm.$t('select_professional_experience_sector') + ':',
                            text: _vm.$t('professional_exp_sector') + '*',
                            label: _vm.$t('professional_experience_sector'),
                            type: 'checkbox',
                            columns: 3,
                            required: true,
                            style: '',
                            popupStyle: 'min-width: 900px;width: 55%;'
                        },"datainput":{
                            id: 'value_evaluator_professional_experience_sector',
                            name: 'evaluator[professional_experience_sector][]'
                        },"feed":_vm.getFeeds.feed_professional_experience_sector,"selected_input":_vm.getterEvaluatorData.professional_experience_sector !== undefined ? _vm.getterEvaluatorData.professional_experience_sector : []}})]:[_c('div',{staticStyle:{"display":"ruby-base"}},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"position":"absolute","width":"160px","min-width":"160px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('professional_exp_sector') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"172px","display":"block"}},[_vm._v(_vm._s(Object.values(_vm.getterEvaluatorData.professional_experience_sector).join()))])])]],2)]),_c('div',{staticClass:"gh-row-line"},[_c('div',{staticClass:"gh-row-column"},[_c('GhChecks',{attrs:{"datalabel":{
                        text: _vm.$t('disponibility_to_travel'),
                        style: '',
                        class: '',
                        id: ''
                    },"datainput":{
                        required: false,
                        disabled: _vm.$root._route.params.view,
                        selected: _vm.getterEvaluatorData.disponibility_to_travel !== undefined ? _vm.getterEvaluatorData.disponibility_to_travel === 1 : false,
                        type: 'checkbox',
                        id: 'evaluator_disponibility_to_travel',
                        value: 1,
                        style: 'margin-left: 172px;',
                        name: 'evaluator[disponibility_to_travel]'
                    }}})],1)]),_c('div',{staticClass:"gh-row-line"},[_c('div',{class:!_vm.$root._route.params.view ? 'gh-row-column no-min-width' : 'gh-row-column-flex'},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{key:'refresh_communities_where_can_work_' + _vm.refreshCommunity,ref:"refCommunitiesWhereCanWork",attrs:{"datablock":{
                            id: 'evaluator_communities_where_can_work',
                            class: 'T15_b',
                            subtitle: _vm.$t('select_communities_where_can_work') + ':',
                            text: _vm.$t('communities_where_can_work') + '*',
                            label: _vm.$t('communities_where_can_work'),
                            type: 'checkbox',
                            columns: 3,
                            required: true,
                            inputAll: true,
                            style: '',
                            popupStyle: 'min-width: 900px;width: 55%;'
                        },"datainput":{
                            id: 'value_evaluator_communities_where_can_work',
                            name: 'evaluator[communities_where_can_work][]'
                        },"feed":_vm.getterCommunityByCountry !== undefined ? _vm.getterCommunityByCountry : [],"selected_input":_vm.getterEvaluatorData.communities_where_can_work !== undefined ? _vm.getterEvaluatorData.communities_where_can_work : []}})]:[_c('div',{staticStyle:{"display":"ruby-base"}},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"position":"absolute","width":"160px","min-width":"160px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('communities_where_can_work') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"172px","display":"block"}},[_vm._v(_vm._s(Object.values(_vm.getterEvaluatorData.communities_where_can_work).join(', ')))])])]],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }